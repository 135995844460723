$( document ).ready(function()
{
    activateWebshopEvents();
});

/**
 * Activate webshop events
 */
function activateWebshopEvents()
{
    //Remove click effect from coupon buttons
    $('a[data-pdev-action="ws-add-coupon"], a[data-pdev-action="ws-remove-coupon"]').off('click');

    //Add coupon code
    //Selector: ws-add-coupon
    $('a[data-pdev-action="ws-add-coupon"]').on('click', function()
    {
        addCouponCart()
    });

    //Remove coupon code
    //Selector: ws-remove-coupon
    $('a[data-pdev-action="ws-remove-coupon"]').on('click', function()
    {
        removeCouponCart();
    });

    //Delete item from cart
    //Selector: ws-delete-cart
    //Needs attributes: data-pdev-cart-key, data-pdev-pid
    $('a[data-pdev-action="ws-delete-cart"]').on('click', function()
    {
        var cart_key = $(this).attr("data-pdev-cart-key");
        var pid = $(this).attr("data-pdev-pid");
        deleteFromCart(cart_key, pid);
    });

    //Update cart item amount
    //Selector: ws-cart-amount
    //Needs attributes: data-pdev-cart-key, data-pdev-pid
    $('input[data-pdev-action="ws-cart-amount"]').on('change', function()
    {
        var cart_key = $(this).attr("data-pdev-cart-key");
        var pid = $(this).attr("data-pdev-pid");
        updateCartAmount(cart_key, pid, this.value);
    });

    //Add item to cart
    //Selector: ws-add-cart
    //Can use attributes: data-pdev-cart-amount, data-pdev-cart-note
    $('a[data-pdev-action="ws-add-cart"]').on('click', function()
    {
        var pid = $(this).attr("data-pdev-pid");
        addToCart(pid);
    });

    //Change main product image
    //Selector: set-product-image
    //Needs element: #pdev-product-main-image
    $('img[data-pdev-action="set-product-image"]').on('click', function()
    {
        var src = $(this).attr("data-image");
        var image = $('#pdev-product-main-image');
        image.attr("src",src);
    });

    //Change shipping method
    //Selector: ws-shipping
    $('select[data-pdev-action="ws-shipping"]').on('change', function()
    {
        changeShippingMethod(this.value);
    });

    //Create order
    $('*[data-pdev-action="ws-create-order"]').on('click', function()
    {
        createOrder();
    });

    //Create order payment
    $('*[data-pdev-action="ot-create-payment"]').on('click', function()
    {
        var track_id = $(this).attr("data-pdev-track-id");
        createOrderPayment(track_id);
    });

    //Show / hide different shipping
    $('input[data-pdev-action="ws-different-shipping"]').on('click', function()
    {
        if(this.checked)
        {
            $('div[data-pdev-action="ws-different-shipping-show"]').show();
        }
        else
        {
            $('div[data-pdev-action="ws-different-shipping-show"]').hide();
        }
    });

    //Show / hide create account
    $('input[data-pdev-action="ws-create-account"]').on('click', function()
    {
        if(this.checked)
        {
            $('div[data-pdev-action="ws-create-account-show"]').show();
        }
        else
        {
            $('div[data-pdev-action="ws-create-account-show"]').hide();
        }
    });






}

