$( document ).ready(function()
{
    if($('*[data-pdev-action="py-method-change"]').length > 0 || $('*[data-pdev-action="py-create-payment"]').length > 0)
    {
        activatePaymentScreenEvents();
    }
});

/**
 * Activate payment screen events
 */
function activatePaymentScreenEvents()
{

    var methodSelect = $('select[data-pdev-action="py-method-change"]');
    var method = methodSelect.val();
    paymentMethodInfo(method);
    paymentMethodChange();

    methodSelect.on('change', function()
    {
        paymentMethodInfo(this.value);
        method = methodSelect.val();
    });

    /**
     *  Create mollie payment
     */
    $('*[data-pdev-action="py-create-payment"]').on('click', function()
    {
        //If method select isn't set check for payment method id in data attribute
        if(!method)
        {
            var paymentMethod = $(this).attr("data-pdev-payment-method");
            if(paymentMethod)
            {
                method = paymentMethod;
            }
        }
        var track_id = $(this).attr("data-pdev-track-id");
        createMolliePayment(track_id, method);
    });

}

