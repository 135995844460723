/**
 * Login customer
 */
function loginCustomer()
{
    var email = $('#pdev-customer-email').val();
    var password = $('#pdev-customer-password').val();

    $.ajax
    ({
        type: "POST",
        dataType: 'json',
        url: front_Customer_url,
        data: {option:'loginCustomer', email:email, password:password},
        success: function(data)
        {
            if(parseInt(data['error']) === 0)
            {
                redirectToPage(data['redirect']);
            }
            else
            {
                returnNotification("Oeps", data['msg'], "danger");
            }
        }
    });
}

/**
 * Logout customer
 */
function logoutCustomer()
{
    $.ajax
    ({
        type: "POST",
        dataType: 'json',
        url: front_Customer_url,
        data: {option:'logoutCustomer'},
        success: function(data)
        {
            if(parseInt(data['error']) === 0)
            {
                redirectToPage("/");
            }
        }
    });
}

