/*****************************************
 * Basic usages shared functions for web & web-front
 *****************************************/

/**
 *  PDEV API entry path
 */
const API_entry = '/control/PDEV_API/';

/**
 * Return GET method parameter
 * @param sParam
 * @returns {*}
 */
var getUrlParameter = function getUrlParameter(sParam)
{
    var sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++)
    {
        sParameterName = sURLVariables[i].split('=');
        if (sParameterName[0] === sParam)
        {
            return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
        }
    }
    return null;
};

/**
 * Get part of url between /
 * @param index
 * @returns {string}
 */
var getUrlPart = function getUrlParameter(index = 0)
{
    var params = window.location.pathname.split('/').slice(1);
    return params[index];
};

/**
 * Get cookie value
 * @param name
 * @returns {*}
 */
function getCookie(name)
{
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++)
    {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

/**
 * Set input value
 * @param dom_id
 * @param value
 */
function setInputValue(dom_id, value)
{
    $('#'+dom_id).val(value);
}

/**
 * Return input value
 * @param dom_id
 * @returns {*|jQuery|*|*|*|*}
 */
function returnInputValue(dom_id)
{
    return $('#'+dom_id).val();
}

/**
 * Activate search for element @element_type in parent element by text input
 * @param parent_id
 * @param text_input_id
 * @param element_type
 */
function elementsTextInputFilter(parent_id, text_input_id, element_type = 'div')
{
    var $elements = $('#' + parent_id + ' ' + element_type);
    $('#' + text_input_id).keyup(function() {
        var val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase();
        $elements.show().filter(function() {
            var text = $(this).text().replace(/\s+/g, ' ').toLowerCase();
            return !~text.indexOf(val);
        }).hide();
    });
}

/**
 * Activate search for table rows in table body by text input
 * @param table_body_id
 * @param text_input_id
 */
function tableTextInputFilter(table_body_id, text_input_id)
{
    elementsTextInputFilter(table_body_id, text_input_id, 'tr');
}

/**
 * Activate filters
 */
$( document ).ready(async function()
{
    /**
     *  Table search
     *  Requirements: Text input must have unique id and attribute data-search-table='' containing the searchable <tbody> unique id
     */
    $("input[data-search-table]").each(function()
    {
        tableTextInputFilter($(this).data('search-table'), $(this).attr("id"));
    });

    /**
     * Element search
     * Requirements: Text input must have unique id and attribute data-search-element='' containing id of div to search
     * Optional you can add attribute data-search-element-type='' containing which element to search, default is div
     */
    $("input[data-search-element]").each(function()
    {
        var element = $(this).data('search-element-type');
        if(!element || element.length < 1)
        {
            element = "div";
        }
        elementsTextInputFilter($(this).data('search-element'), $(this).attr("id"), element);
    });
});