/**
 *  Content functions for PDEV Webshop
 */

/**
 * Refresh shopping cart output
 */
function refreshCartOutput()
{
    $.ajax
    ({
        type: "POST",
        dataType: 'json',
        url: front_Webshop_shop_shoppingcart_url,
        data: {option:'refreshCartContent'},
        success: function(data)
        {
            $('#pdev-shoppingcart-content').html(data['content']);
            activateWebshopEvents();
        }
    });
}