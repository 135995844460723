/**
 * Activate payment method select change
 */
function paymentMethodChange()
{
    $("#payment_methods").change(function()
    {
        paymentMethodInfo($(this).val());
    });
}

/**
 * Load payment method information
 * @param paymentMethodId
 */
function paymentMethodInfo(paymentMethodId)
{
    $('.payment-method-info').hide();
    if(paymentMethodId == 2)
    {
        $('#bank-transaction').show();
        $('*[data-pdev-action="py-create-payment"]').hide();
    }
    else if (paymentMethodId == 3)
    {
        $('#cash-transaction').show();
        $('*[data-pdev-action="py-create-payment"]').hide();
    }
    else
    {
        $('*[data-pdev-action="py-create-payment"]').show();
    }
}

/**
 * Create Mollie payment for PDEV payment and redirect to Mollie pay page
 * @param payment_track_id
 * @param method
 * @param method_select_id
 * @returns {boolean}
 */
function createMolliePayment(payment_track_id, method = 0, method_select_id = 'payment_methods')
{
    if(!method || method === 0)
    {
        method = $('#' + method_select_id).val();
    }
    if(!method)
    {
        returnNotification("Oeps", 'Selecteer een betaalmethode', 'warning');
        return false;
    }

    $.ajax
    ({
        type: "POST",
        dataType: 'json',
        url: front_PaymentScreen_url,
        data: {option: 'createPaymentMolliePayment', method:method, payment:payment_track_id},
        success: function (data)
        {
            if(parseInt(data['error']) === 1)
            {
                returnNotification("Oeps", data['msg'], 'danger');
            }
            else
            {
                redirectToPage(data['mollie_url']);
            }
        }
    });
}