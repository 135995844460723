//Actions are used by PDEV_Webshop_events.js
//Use data-pdev- attributes and #IDs to use

/**
 * Add product to shopping cart
 * @param pid
 */
function addToCart(pid)
{
    var amount = $('input[data-pdev-cart-amount=' + pid + ']').val();
    if(!amount)
    {
        amount = 1;
    }
    var note = $('textarea[data-pdev-cart-note=' + pid + ']').val();
    if(!note)
    {
        note = null;
    }
    $.ajax
    ({
        type: "POST",
        dataType: 'json',
        url: front_Webshop_shop_shoppingcart_url,
        data: {option:'addToCart', pid:pid, amount:amount, note:note},
        success: function(data)
        {
            var response = $('*[data-pdev-cart-response=' + pid + ']');
            if(response.length > 0)
            {
                response.html(data['text']);
            }
            else
            {
                $('.pdev-cart-response').html(data['text']);
            }
        }
    });
}

/**
 * Update product amount in shopping cart
 * @param cart_key
 * @param pid
 * @param amount
 */
function updateCartAmount(cart_key, pid, amount)
{
    $.ajax
    ({
        type: "POST",
        dataType: 'json',
        url: front_Webshop_shop_shoppingcart_url,
        data: {option:'updateCartAmount', pid:pid, cart_key:cart_key, amount:amount},
        success: function(data)
        {
            refreshCartOutput();
        }
    });
}

/**
 * Delete product from shopping cart
 * @param cart_key
 * @param pid
 */
function deleteFromCart(cart_key, pid)
{
    $.ajax
    ({
        type: "POST",
        dataType: 'json',
        url: front_Webshop_shop_shoppingcart_url,
        data: {option:'deleteFromCart', cart_key:cart_key, pid:pid},
        success: function(data)
        {
            refreshCartOutput();
        }
    });
}

/**
 * Add coupon
 */
function addCouponCart()
{
    var code = $('#pdev-coupon-input').val();
    $.ajax
    ({
        type: "POST",
        dataType: 'json',
        url: front_Webshop_shop_shoppingcart_url,
        data: {option:'addCouponCode', code:code},
        success: function(data)
        {
            if(parseInt(data['error']) === 0)
            {
                returnNotification("Gelukt", data['msg'], "success");
            }
            else
            {
                returnNotification("Oeps", data['msg'], "warning");
            }
            refreshCartOutput();
        }
    });
}

/**
 * Remove used coupon
 */
function removeCouponCart()
{
    $.ajax
    ({
        type: "POST",
        dataType: 'json',
        url: front_Webshop_shop_shoppingcart_url,
        data: {option:'removeCouponCode'},
        success: function(data)
        {
            refreshCartOutput();
        }
    });
}

/**
 * Change shipping method
 * @param shipping_method
 */
function changeShippingMethod(shipping_method)
{
    $.ajax
    ({
        type: "POST",
        dataType: 'json',
        url: front_Webshop_shop_url,
        data: {option:'changeShipping', method:shipping_method},
        success: function(data)
        {
            refreshCartOutput();
        }
    });
}

/**
 * Create new order
 */
function createOrder()
{
    var checkout_form = $('#pdev-ws-checkout-form').serialize();
    var order_btn = $('*[data-pdev-action="ws-create-order"]');
    order_btn.prop("disabled", true);
    $.ajax
    ({
        type: "POST",
        dataType: 'json',
        url: front_Webshop_shop_url,
        data: checkout_form + '&option=createOrder',
        success: function(data)
        {
            if(parseInt(data['error']) === 0)
            {
                createOrderPayment(data['trackid']);
            }
            else
            {
                $('#pdev-ws-order-msg').html(data['error_msg']);
                order_btn.prop("disabled", false);
            }
        }
    });
}

/**
 * Create order payment
 * @param trackid
 */
function createOrderPayment(trackid)
{
    $.ajax
    ({
        type: "POST",
        dataType: 'json',
        url: front_Webshop_shop_url,
        data: {option:'createOrderPayment', trackid:trackid},
        success: function(data)
        {
            if(parseInt(data['error']) === 0)
            {
                window.location = data['redirect'];
            }
            else
            {
                returnNotification("Oeps", data['msg'], "warning")
            }
        }
    });
}